import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Press from "../components/press"

const SecondPage = () => (
  <Layout imageVariation="3">
    <SEO title="Press" />
    <h3 className="uppercase font-bold text-4xl mb-4"> Press</h3>
    <Press />
  </Layout>
)

export default SecondPage
