import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const press = [
  {
    name: "Print Review - Tierra EP",
    press: "FAZE Magazine",
    img: "/images/press/er.jpg",
    url: "https://i.postimg.cc/mrLYF95N/faze-magazine-gru102.jpg",
  },
  {
    name: "Because Of Art return with new Tierra EP",
    press: "Electronic Rush",
    img: "/images/press/er.jpg",
    url: "https://electronicrush.net/because-of-art-return-with-tierra-ep/",
  },
  {
    name:
      "Premiere: Because of Art Launch ONNE Records with Release of Three-Track Astral EP",
    press: "6AM Group",
    img: "/images/press/6am.jpg",
    url:
      "https://www.6amgroup.com/premiere-because-of-art-launch-onne-records-with-release-of-three-track-astral-ep/",
  },
  {
    name: "Review: Astral EP",
    press: "Electronic Rush",
    img: "/images/press/er.jpg",
    url: "https://electronicrush.net/review-because-of-art-astral/",
  },
  {
    name: "Spotlight Sessions",
    press: "Evermix",
    img: "/images/press/evermix.jpg",
    url: "https://evermix.fm/blogs/news/spotlight-sessions-because-of-art",
  },
]

const Press = ({ siteTitle }) => (
  <div>
    {press.map((item, key) => (
      <a href={item.url} target="_blank">
        <div className="mb-4">
          <p className="font-bold text-lg uppercase hover:text-yellow-500">
            {item.name}
          </p>
          <p className="">{item.press}</p>
        </div>
      </a>
    ))}
  </div>
)

Press.propTypes = {
  siteTitle: PropTypes.string,
}

Press.defaultProps = {
  siteTitle: ``,
}

export default Press
